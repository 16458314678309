import {Serializer} from '../../common/http/serializer';
import { User } from './user.model';

export class UserSerializer implements Serializer {
  fromJson(json: any): User {
    const user = new User();
    user.id = json.user.id;
    user.name = json.user.name;
    user.imageUrl = json.user.imageUrl;
    user.emailVerified = json.user.emailVerified;
    user.provider = json.user.provider;

    return user;
  }

  toJson(user: User): any  {
    return {
      id: user.id,
      name: user.name,
      imageUrl : user.imageUrl,
      emailVerified : user.emailVerified,
      provider : user.provider
    };
  }
}
