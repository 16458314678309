import { AreaResource, AreaType } from '../../area.resource';
import { Resource } from 'src/app/core/common/http/resource.model';
import { FunctionsResource } from './functions.resource';

export class AreaEsightResource extends AreaResource {
  public static readonly TYPE = AreaType.esight;

  profile: ProfileResource;
  functions: FunctionsResource[];
  commesse: string;
  notes: string;

   constructor() {
    super(AreaEsightResource.TYPE);

}}


export class ProfileResource extends Resource {
  id: number;
  description: string;

  constructor() {
    super();

  }
}



