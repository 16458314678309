import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaPitecoResource, UserOpAfTypeResource, BelongingOfficeResource} from './areapiteco.resource';
import { AreaPitecoModel } from '../model/area-piteco.model';


export class AreaPitecoSerializer implements Serializer {
  fromJson(json: any): AreaPitecoResource {

    const resource = new AreaPitecoResource();

    resource.type_user = new UserOpAfTypeResource();
    resource.type_user.id = json.type_user.id;
    resource.type_user.type = json.type_user.type;
    resource.belonging_office = new BelongingOfficeResource();
    resource.belonging_office.id = json.belonging_office.id;
    resource.belonging_office.description = json.belonging_office.description;
    resource.notes = json.notes;

    return resource;
  }

  toJson(model: AreaPitecoModel): any  {

    const resource = new AreaPitecoResource();

    resource.type_user = new UserOpAfTypeResource();
    resource.type_user.id = model.type_user[0].id;
    resource.type_user.type = model.type_user[0].type;
    resource.belonging_office = new BelongingOfficeResource();
    resource.belonging_office.id = model.belonging_office[0].id;
    resource.belonging_office.description = model.belonging_office[0].description;
    resource.notes = model.notes;

    return resource;


  }
}
