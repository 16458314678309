import {  Component } from '@angular/core';
import { NgbActiveModal, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Confirm</h4>
    </div>
    <div class="modal-body">
      <p>Stai inviando la richiesta al passaggio successivo, vuoi procedere?</p>
    <div></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default waves-effect"  (click)="confirm()" >Conferma</button>
        <button type="button" class="btn btn-danger waves-effect waves-light" (click)="cancel()" >Cancel</button>
      </div>
  `
})
export class SendRaasConfirmComponent {

   constructor(
    config: NgbProgressbarConfig,
    public activeModal: NgbActiveModal
    ) {

      config.max = 100;
      config.striped = true;
      config.animated = true;
      config.type = 'success';
    }

  confirm() {
    // Restituisco la confirma
    this.activeModal.close({confirm: true});

  }
  cancel() {
    this.activeModal.dismiss('Close click');
  }

}















