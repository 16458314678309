import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaActiveDirectoryResource, } from './area-active-directory.resource';
import { AreaActiveDirectoryModel } from '../model/area-active-directory.model';



export class AreaActiveDirectorySerializer implements Serializer { 


  fromJson(json: any): AreaActiveDirectoryResource { 
  
    const resource = new AreaActiveDirectoryResource();

    resource.notes = json.notes;
    resource.phone = json.phone;

    return resource;
  }

  toJson(model: AreaActiveDirectoryModel): any  {

    const resource = new AreaActiveDirectoryResource();
    
    resource.notes = model.notes;
    resource.phone = model.phone;

    return resource;
  }
}
