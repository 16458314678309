import { Model } from 'src/app/core/common/model/model';

export class CommessaModel extends Model {

  id: string;
  project_name: string;
  active: boolean;
  cdc_type: string;
  project_description: string;
  
  constructor() {
    super();
  }

}
