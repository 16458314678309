import { UserResource } from 'src/app/user/resource/user.resource';
import { ModelAssembler } from 'src/app/core/common/model/model.assembler';
import { Injectable } from '@angular/core';
import { InternalBeneficiaryModel } from '../find-internal-beneficiary/model/internal.beneficiary.model';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryAssembler implements ModelAssembler<UserResource, InternalBeneficiaryModel> {
  toModel(entity: UserResource): InternalBeneficiaryModel {

    const model = new InternalBeneficiaryModel();
     model.firstName = entity.first_name;
     model.lastName = entity.last_name;
     model.email = entity.email;
     model.cdc_code = entity.cdc;
     model.user_id = entity.id;
     model.matricola = entity.matricola
     return model;
  }
  toCollectionModel(resource: UserResource[]): InternalBeneficiaryModel[] {
    return undefined;
  }

}
