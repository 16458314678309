import { ActionResource } from './action.resource';
import { AdditionalFields } from './additionalFields.resource';
import { Resource } from 'src/app/core/common/http/resource.model';

export class ApprovalActionResource extends Resource {

  action: ActionResource;
  additional_fields: AdditionalFields;

  constructor() {
    super();
  }

}



