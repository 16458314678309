import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'ripuDatePipe'
})
export class RipuDatePipeFormat extends DatePipe implements PipeTransform {

  static readonly DATE_TIME_FMT = `dd/MM/yyyy , HH:mm:ss`;

  transform(value: any, args?: any): any {
    return super.transform(value, RipuDatePipeFormat.DATE_TIME_FMT);
  }
}
