import { Serializer } from 'src/app/core/common/http/serializer';
import { CdcResource } from './cdc.resource';
import { CdcModel } from '../model/cdc.model';

export class CdcSerializer implements Serializer {

  fromJson(json: any): CdcModel {

    const model = new CdcModel();
    model.id = json.udb_code + '-' + json.area_code + '-' + json.cdc_code;
    model.cdc_code = json.cdc_code;

    return model;
  }

  toJson(model: CdcModel): any  {

    const resource = new CdcResource();
    const arr = model.id.split('-');

    resource.udb_code = arr[0];
    resource.area_code = arr[1];
    resource.cdc_code = arr[2];

    return resource;
  }

}
