import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaFlooraResource, TypePortalResource, UserFTypeResource } from './area-floora.resource';
import { AreaFlooraModel } from '../model/area-floora.model';



export class AreaFlooraSerializer implements Serializer {
  fromJson(json: any): AreaFlooraResource {


    const resource = new AreaFlooraResource();
    resource.type_portal = new TypePortalResource();
    resource.type_portal.id = json.type_portal.id;
    resource.type_portal.description = json.type_portal.description;
    resource.type_user = new UserFTypeResource();
    resource.type_user.id = json.type_user.id;
    resource.type_user.type = json.type_user.type;
    resource.profile = json.profile;
    resource.notes = json.notes;

    return resource;
  }

  toJson(model: AreaFlooraModel): any  {
    

    const resource = new AreaFlooraResource();

    resource.type_portal = new TypePortalResource();
    resource.type_portal.id = model.type_portal[0].id;
    resource.type_portal.description = model.type_portal[0].description;
    resource.type_user = new UserFTypeResource();
    resource.type_user.id = model.type_user[0].id;
    resource.type_user.type = model.type_user[0].type;
    resource.profile = model.profile;
    resource.notes = model.notes;

    return resource;


  }
}
