import { AreaResource, AreaType } from '../../area.resource';
import { Resource } from 'src/app/core/common/http/resource.model';

export class AreaSupervisionResource extends AreaResource {
  public static readonly TYPE = AreaType.supervision_systems;
  system: SystemResource;
  notes: string;
  constructor() {
    super(AreaSupervisionResource.TYPE);
  }
}


export class SystemResource extends Resource {
  id: number;
  description: string;
  constructor() {
    super();

  }
}
