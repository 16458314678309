import { Resource } from 'src/app/core/common/http/resource.model';
import { RequestResource } from './request.resource';

export class RequestCreateResource extends Resource {

  request: RequestResource;

  constructor() {
    super();
  }

}
