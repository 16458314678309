
import { AreaResource, AreaType } from '../../area.resource';

export class AreaIgatewayResource extends AreaResource {
   public static readonly TYPE =  AreaType.igateway;

   equivalent_profile_user: string;
   notes: string;

   constructor() {
    super(AreaIgatewayResource.TYPE);
   }
}

