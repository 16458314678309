import {Component, Input} from '@angular/core';
import { InternalBeneficiaryModel } from './find-internal-beneficiary/model/internal.beneficiary.model';

@Component({
  selector: 'app-beneficiary-data-internal',
  templateUrl: './beneficiary-data-internal.html'
})
export class BeneficiaryDataInternalComponent {

  @Input() beneficiary: InternalBeneficiaryModel ;


  ngOnInit() {
    console.log(this.beneficiary);
  }

}

