
import { AreaResource, AreaType } from '../../area.resource';

export class AreaActiveDirectoryResource extends AreaResource {
   public static readonly TYPE =  AreaType.account_active_directory;

   phone: string;
   notes: string;

   constructor() {
    super(AreaActiveDirectoryResource.TYPE);
   }
}

