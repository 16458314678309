import { TaskResource } from './task.resource';
import { RequestResource } from 'src/app/richieste/resource/request.resource';
import { Resource } from 'src/app/core/common/http/resource.model';

export class ApprovalInfoResource extends Resource {
  type: string;
  process_id: string;
  status: string;
  request: RequestResource;
  task: TaskResource;

  constructor(type: string) {
    super();
    this.type = type;
  }

}

export class StateResource {
  id: string;
  type: string;
}

export class HistoricApprovalResource extends ApprovalInfoResource {
  public static readonly TYPE = 'historic';

   constructor() {
    super(HistoricApprovalResource.TYPE);

}
}

export class ApprovalResource extends ApprovalInfoResource {
  public static readonly TYPE = 'present';

   constructor() {
    super(ApprovalResource.TYPE);

}

}
