import { Resource } from 'src/app/core/common/http/resource.model';
import { AreaResource, AreaType } from '../../../area.resource';

export class AreaP2WebResource extends AreaResource {
   public static readonly TYPE =  AreaType.p2Web;

   type_module: UserOpAfTypeResource;
   cdc: string;
   notes: string;

   constructor() {
    super(AreaP2WebResource.TYPE);
   }
}

export class UserOpAfTypeResource extends Resource {
  id: string;
  description: string;

  constructor() {
    super();

  }
}


