import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaEtimeResource } from './area-etime.resource';
import { AreaEtimeModel } from '../model/area-etime.model';


export class AreaEtimeSerializer implements Serializer { 

  fromJson(json: any): AreaEtimeResource { 

  const resource = new AreaEtimeResource();

    resource.cdc = json.cdc;
    resource.notes = json.notes;

    return resource;
  }

  toJson(model: AreaEtimeModel): any  {

    const resource = new AreaEtimeResource();

 
    resource.cdc = model.cdc;
    resource.notes = model.notes;

    return resource;


  }
}
