import { AreaResource, AreaType } from '../../area.resource';
import { CommessaResource } from './commessa.resource';
import { CdcResource } from './cdc.resource';
import { Resource } from 'src/app/core/common/http/resource.model';

export class AreaTempozeroResource extends AreaResource {
  public static readonly TYPE = AreaType.tempozero;

   type_user: UserOfAtzTypeResource;
   personal_approval_power: number;
   commesse: CommessaResource[];
   cdc: CdcResource[];
   notes: string;
   cm_riferimento : any;

   constructor() {
    super(AreaTempozeroResource.TYPE);

}}


export class UserOfAtzTypeResource extends Resource {
  id: number;
  type: string;
  description: string;
  constructor() {
    super();
  }
}




