import { AreaModel } from "../../area.model";


export class AreaEtimeModel extends AreaModel  {

    public cdc: string; 
    public notes: string; 

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
