import { Injectable } from '@angular/core';
import { snakeCase } from 'snake-case';
import { TaskStatusResource, TaskStatusResourceLabels, CensusStatusResourceLabels, CensusStatusResource, RaasStatusResource, RaasStatusResourceLabels } from 'src/app/approvazioni/resource/task.resource';
import { AreaType } from 'src/app/richieste/area/area.resource';
import { FALSE, TRUE } from './const';
const { isBlank } = require('npm-stringutils');




@Injectable({
  providedIn: 'root',
})
export class AppUtilService {

  /**
   * Renderizza il l'immagine di utenza ( beneficiario, richiedente o utente loggato)
   * @param user
   */

  TRUE: boolean = TRUE;
  FALSE: boolean = FALSE;

  renderUser(user) {
    const firstName = user.first_name;
    const lastName = user.last_name;
    const displayName = firstName + '  ' + lastName;
    return `
         <div class="d-flex align-items-center">
                  <div class="m-r-10">
                     <a class="btn btn-circle btn-success text-white profile-no-effect">${firstName.charAt(0).toUpperCase() + '' + lastName.charAt(0).toUpperCase()}</a>
                  </div>
                  <div>
                     <h4 class="m-b-0 font-16">${displayName}</h4>
                  </div>
               </div>`;
  }


  renderSubmitter(submitter) {
    return this.renderSubmitterHTML(submitter.first_name, submitter.last_name, submitter.display_name);
  }

  renderSubmitterDisplayName(submitter, type, full, meta) {
    const display_name = submitter.first_name + '  ' + submitter.last_name;
    return this.renderSubmitterHTML(submitter.first_name, submitter.last_name, display_name);
  }

  renderSubmitterHTML(first_name, last_name, display_name) {
    return `<div class="d-flex align-items-center text-center">
            <div class="m-r-10">
                <a class="btn btn-circle btn-success text-white profile-no-effect">${first_name.charAt(0).toUpperCase() + '' + last_name.charAt(0).toUpperCase()}</a>
            </div>
            <div>
                ${display_name}
            </div>
          </div>`;
  }


  renderReason(reason) {
    if (reason && reason.description) {
      return reason.description
    } else return '';
  }



  /**
   * Controlla se il button annulla deve essere visibile o no in base allo status
   * @param data 
   * 
   */
  visibleButtonAnnullaByStatus(data: string): boolean {
    let buttonAnnullaIsVisible;
    switch (data.toLowerCase()) {
      case (TaskStatusResource.APPROVED):
      case (TaskStatusResource.COMPLETED):
      case (TaskStatusResource.DENY):
      case (TaskStatusResource.CLOSED):
      case (TaskStatusResource.OPEN):
      case (TaskStatusResource.TICKET_COMPLETED):
      case (TaskStatusResource.CANCELED): {
        buttonAnnullaIsVisible = false;
        break;
      }
      case (TaskStatusResource.PENDING):
      case (TaskStatusResource.DRAFT):
      case (TaskStatusResource.TICKET_CREATED):
      case (TaskStatusResource.PROCESSING): {
        buttonAnnullaIsVisible = true;
        break;
      }
      default: {
        buttonAnnullaIsVisible = false;
      }
    }
    return buttonAnnullaIsVisible;
  }

  /**
   * Renderizza il button annulla in base allo status
   * @param data 
   */
  renderButtonAnnulla(data: string) {
    let buttonAnnullaIsVisible = false;
    if (data != null && typeof data != typeof undefined) {
      switch (data.toLowerCase()) {
        case (TaskStatusResource.PENDING):
        case (TaskStatusResource.DRAFT): {
          buttonAnnullaIsVisible = true;
          break;
        }
      }
    }
    let button = '';
    if (buttonAnnullaIsVisible === TRUE) {
      button = `   <div class="col text-center">
      <div class="btn-group mr-2">
      <button id="btnCancel" style="border: none; padding: 0; background: none;" class="btn-approve-action unstyled-button" placement="top" data-toggle="tooltip" data-placement="top" title="Annulla"  type="button"><i class="fas fa-eraser fa-2x" aria-hidden="true" style="color:#FF0000;"></i></button>
      </div>
    </div>`;
    } else {
      button = `   <div class="col text-center">
      <div class="btn-group mr-2">
      <button id="btnCancel" style="border: none; padding: 0; background: none;" class="btn-approve-action unstyled-button" placement="top" data-toggle="tooltip" data-placement="top" title="Annulla"  type="button" disabled><i class="fas fa-eraser fa-2x" aria-hidden="true"></i></button>
      </div>
    </div>`;
    }

    return button;
  }

  renderButtonInCharge(data) {
    return this.renderStatus(data);
  }





  taskLabelCommon: any = {
    'approved': { spanClass: 'label-success', label: TaskStatusResourceLabels.APPROVED },
    'completed': { spanClass: 'label-success', label: TaskStatusResourceLabels.COMPLETED },
    'pending': { spanClass: 'label-warning', label: TaskStatusResourceLabels.PENDING },
    'deny': { spanClass: 'label-danger', label: TaskStatusResourceLabels.DENY },
    'draft': { spanClass: 'label-warning', label: TaskStatusResourceLabels.DRAFT },
    'closed': { spanClass: 'label-danger', label: TaskStatusResourceLabels.CLOSED },
    'open': { spanClass: 'label-danger', label: TaskStatusResourceLabels.OPEN },
    'ticket_completed': { spanClass: 'label-success', label: TaskStatusResourceLabels.TICKET_COMPLETED },
    'ticket_created': { spanClass: 'label-success', label: TaskStatusResourceLabels.TICKET_CREATED },
    'processing': { spanClass: 'label-warning', label: TaskStatusResourceLabels.PROCESSING },
    'annulated': { spanClass: 'label-danger', label: TaskStatusResourceLabels.ANNULATED },
    'canceled': { spanClass: 'label-danger', label: TaskStatusResourceLabels.CANCELED }
  }

  

  /**
    * Renderizza il l'immagine dello status di una tipologia di richiesta o di processo
    * @param user
    */
  renderStatus(data: any) {
    if(data == null){return null;}
    let obj = this.getRenderStatus(data);
    return `<span class="label ${obj.spanClass} font-weight-100">${obj.label.toUpperCase()}</span>`; 
  }

  getRenderStatus(data: any) {
    let obj = this.getRenderCommon(data);
    if(obj == null){
      if(data.toLowerCase() == TaskStatusResource.CANCELED){
        obj = this.taskLabelCommon[TaskStatusResource.CANCELED];
      }else{
        obj = { spanClass: 'label-danger', label: data };
      }
    }
    return obj;
  }




  getRenderCommon(data: any){
    let obj = null;
    switch (data.toLowerCase()) {
      case (TaskStatusResource.APPROVED): {
        obj = this.taskLabelCommon[TaskStatusResource.APPROVED];
        break;
      }
      case (TaskStatusResource.COMPLETED): {
        obj = this.taskLabelCommon[TaskStatusResource.COMPLETED];
        break;
      }
      case (TaskStatusResource.PENDING): {
        obj = this.taskLabelCommon[TaskStatusResource.PENDING];
        break;
      }
      case (TaskStatusResource.DENY): {
        obj = this.taskLabelCommon[TaskStatusResource.DENY];
        break;
      }
      case (TaskStatusResource.DRAFT): {
        obj = this.taskLabelCommon[TaskStatusResource.DRAFT];
        break;
      }
      case (TaskStatusResource.CLOSED): {
        obj = this.taskLabelCommon[TaskStatusResource.CLOSED];
        break;
      }
      case (TaskStatusResource.OPEN): {
        obj = this.taskLabelCommon[TaskStatusResource.OPEN];
        break;
      }
      case (TaskStatusResource.TICKET_COMPLETED): {
        obj = this.taskLabelCommon[TaskStatusResource.TICKET_COMPLETED];
        break;
      }
      case (TaskStatusResource.CANCELED): {
        obj = this.taskLabelCommon[TaskStatusResource.ANNULATED];
        break;
      }
      case (TaskStatusResource.TICKET_CREATED): {
        obj = this.taskLabelCommon[TaskStatusResource.TICKET_CREATED];
        break;
      }
      case (TaskStatusResource.PROCESSING): {
        obj = this.taskLabelCommon[TaskStatusResource.PROCESSING];
        break;
      }
    }
    return obj;
  }




  getRenderStatus_ProcessDetailComponent(data: any) {
    return this.getRenderCommon1(data);
  }
  getRenderStatus_AssunzioniApprovazioniProcessDetail(data: any) {
    return this.getRenderCommon1(data);
  }
  getRenderStatus_TrasferteApprovazioniProcessDetail(data: any) {
    return this.getRenderCommon1(data);
  }
  getRenderStatus_DataApprovazioniProcessDetail(data: any) {
    return this.getRenderCommon1(data);
  }
  getRenderCommon1(data: any) {
    let obj = this.getRenderCommon(data);
    if(obj == null){
      obj = { spanClass: 'label-danger', label: data };
    }
    return obj;
  }

  getRenderStatus_AssunzioniGestioneProcessDetail(data: any) {
    return this.getRenderCommon2(data);
  }
  getRenderStatus_AssunzioniRichiesteProcessHrBpDetail(data: any) {
    return this.getRenderCommon2(data);
  }
  getRenderCommon2(data: any) {
    let obj = this.getRenderCommon(data);
    if(obj == null){
      switch (data.toLowerCase()) {
        case (TaskStatusResource.CANCELED): {
          obj = this.taskLabelCommon[TaskStatusResource.CANCELED];
          break;
        }

        case (TaskStatusResource.CENSUS_CLOSE): {
          obj = { spanClass: 'label-warning', label: TaskStatusResourceLabels.CENSUS_CLOSE };
          break;
        }
        case (TaskStatusResource.SEND): {
          obj = { spanClass: 'label-success', label: TaskStatusResourceLabels.SEND };
          break;
        }
  
        case (TaskStatusResource.TICKET_NUMBER_CREATED): {
          obj = { spanClass: 'label-success', label: TaskStatusResourceLabels.TICKET_NUMBER_CREATED };
          break;
        }
  
        case (TaskStatusResource.TICKET_GENERATE_PROTOCOL_NUMBER): {
          obj = { spanClass: 'label-success', label: TaskStatusResourceLabels.TICKET_GENERATE_PROTOCOL_NUMBER };
          break;
        }
  
        case (TaskStatusResource.NO_SHOW): {
          obj = { spanClass: 'label-danger', label: TaskStatusResourceLabels.NO_SHOW };
          break;
        }
  
        default: {
          obj = { spanClass: 'label-danger', label: data };
        }
      }
    }
    
    return obj;
  }

  /*  Fine Render Status */

  /*  Raas Status */

  renderRaasStatus(data) {
    let spanClass, label;

    switch (data.toLowerCase()) {
      case (RaasStatusResource.APPROVED): {
        spanClass = 'label-success';
        label = RaasStatusResourceLabels.APPROVED
        break;
      }
      case (RaasStatusResource.DRAFT): {
        spanClass = 'label-warning';
        label = RaasStatusResourceLabels.DRAFT
        break;
      }
      case (RaasStatusResource.DENY): {
        spanClass = 'label-danger';
        label = RaasStatusResourceLabels.DENY;
        break;
      }
      case (RaasStatusResource.PENDING): {
        label = RaasStatusResourceLabels.PENDING;
        spanClass = 'label-warning';
        break;
      }
      case (RaasStatusResource.CLOSE): {
        label = RaasStatusResourceLabels.CLOSE;
        spanClass = 'label-danger';
        break;
      }
      default: {
        label = data;
        spanClass = 'label-danger';
      }
    }


    return `<span class="label ${spanClass} font-weight-100">${label.toUpperCase()}</span>`;
  }


  /*  Fine Raas Status */


  /*  Census Status */

  renderCensusStatus(data) {
    let spanClass, label;

    switch (data.toLowerCase()) {
      case (CensusStatusResource.IN_MANAGE): {
        spanClass = 'label-success';
        label = CensusStatusResourceLabels.IN_MANAGE
        break;
      }
      case (CensusStatusResource.PROCESSING): {
        spanClass = 'label-success';
        label = CensusStatusResourceLabels.PROCESSING
        break;
      }
      case (CensusStatusResource.TO_MANAGE): {
        spanClass = 'button-label-warning';
        label = CensusStatusResourceLabels.TO_MANAGE_MESSAGE
        break;
      }
      case (CensusStatusResource.SUSPENDEND): {
        spanClass = 'label-danger';
        label = CensusStatusResourceLabels.SUSPENDEND;
        break;
      }
      case (CensusStatusResource.WAITING): {
        label = CensusStatusResourceLabels.WAITING;
        spanClass = 'label-warning';
        break;
      }
      case (CensusStatusResource.COMPLETED): {
        label = CensusStatusResourceLabels.COMPLETED;
        spanClass = 'label-success';
        break;
      }
      case (CensusStatusResource.IN_COMPLETION): {
        label = CensusStatusResourceLabels.IN_COMPLETION;
        spanClass = 'label-success';
        break;
      }
      case (CensusStatusResource.IN_STREGHT): {
        label = CensusStatusResourceLabels.IN_STREGHT;
        spanClass = 'label-success';
        break;
      }
      case (CensusStatusResource.NO_SHOW): {
        label = CensusStatusResourceLabels.NO_SHOW;
        spanClass = 'label-danger';
        break;
      }
      case (CensusStatusResource.PROPOSAL_CANCELED): {
        label = CensusStatusResourceLabels.PROPOSAL_CANCELED;
        spanClass = 'label-danger';
        break;
      }
      case (CensusStatusResource.PROPOSAL_CREATED): {
        label = CensusStatusResourceLabels.PROPOSAL_CREATED;
        spanClass = 'label-success';
        break;
      }
      case (CensusStatusResource.CENSUS_CLOSE): {
        label = CensusStatusResourceLabels.CENSUS_CLOSE;
        spanClass = 'label-danger';
        break;
      }
      case (CensusStatusResource.TO_FINALIZED): {
        label = CensusStatusResourceLabels.TO_FINALIZED;
        spanClass = 'label-warning';
        break;
      }
      default: {
        label = data;
        spanClass = 'label-danger';
      }
    }
    if (data.toLowerCase() == CensusStatusResource.TO_MANAGE) {
      return `<div class="col text-center">
           <div class="btn-group mr-2">
         <button id="btnApprove" style="border: none;padding: 0;background: none" class="btn-approve-action unstyled-button" placement="top" data-toggle="tooltip" data-placement="top" title="Prendi in carico"  type="button"><i class="fas fa-check-circle fa-2x" aria-hidden="true" style="color:#4CC417;"></i></button>
         </div>
         <div class="btn-group mr-2">
             <button id="btnRejected" style="border: none;padding: 0;background: none" class="btn-approve-action unstyled-button" placement="top" data-toggle="tooltip" data-placement="top" title="Riassegna"  title="Rifiuta" type="button"><i class="fas fas fa-times-circle fa-2x" aria-hidden="true" style="color:#FF0000;"></i></button>
         </div>
       </div>`;
    } else {
      return `
        <div class="col text-center">
         <div class="btn-group mr-2">
         <span class="label ${spanClass} font-weight-100">${label.toUpperCase()}</span>
         </div>
        </div>`;
    }

  }

  getRenderCensusStatus_AssunzioniRichiesteProcessHrBpDetail(data: any) {
    let spanClass, label;
    switch (data.toLowerCase()) {
      case (CensusStatusResource.COMPLETED): {
        spanClass = 'label-success';
        label = CensusStatusResourceLabels.COMPLETED
        break;
      }
      case (CensusStatusResource.IN_MANAGE): {
        spanClass = 'label-success';
        label = CensusStatusResourceLabels.IN_MANAGE
        break;
      }
      case (CensusStatusResource.PROCESSING): {
        spanClass = 'label-warning';
        label = CensusStatusResourceLabels.PROCESSING
        break;
      }
      case (CensusStatusResource.SUSPENDEND): {
        spanClass = 'label-danger';
        label = CensusStatusResourceLabels.SUSPENDEND;
        break;
      }
      case (CensusStatusResource.TO_MANAGE): {
        label = CensusStatusResourceLabels.TO_MANAGE;
        spanClass = 'label-warning';
        break;
      }
      case (CensusStatusResource.WAITING): {
        label = CensusStatusResourceLabels.WAITING;
        spanClass = 'label-danger';
        break;
      }
      case (CensusStatusResource.PROPOSAL_CREATED): {
        label = CensusStatusResourceLabels.PROPOSAL_CREATED;
        spanClass = 'label-success';
        break;
      }
      case (CensusStatusResource.TO_MANAGE_MESSAGE): {
        label = CensusStatusResourceLabels.TO_MANAGE_MESSAGE;
        spanClass = 'label-warning';
        break;
      }
      case (CensusStatusResource.CENSUS_CLOSE): {
        label = CensusStatusResourceLabels.CENSUS_CLOSE;
        spanClass = 'label-danger';
        break;
      }
      case (CensusStatusResource.IN_COMPLETION): {
        label = CensusStatusResourceLabels.IN_COMPLETION;
        spanClass = 'label-warning';
        break;
      }
      case (CensusStatusResource.IN_STREGHT): {
        label = CensusStatusResourceLabels.IN_STREGHT;
        spanClass = 'label-warning';
        break;
      }
      case (CensusStatusResource.NO_SHOW): {
        label = CensusStatusResourceLabels.NO_SHOW;
        spanClass = 'label-danger';
        break;
      }
      case (CensusStatusResource.PROPOSAL_CANCELED): {
        label = CensusStatusResourceLabels.PROPOSAL_CANCELED;
        spanClass = 'label-danger';
        break;
      }
      case (CensusStatusResource.TO_FINALIZED): {
        label = CensusStatusResourceLabels.TO_FINALIZED;
        spanClass = 'label-warning';
        break;
      }
      default: {
        label = data;
        spanClass = 'label-danger';
      }
    }
    return {
      spanClass: spanClass,
      label: label
    }
  }




  /*  Fine Census Status */

  /**
 * Renderizza il logo dell'area
 * @param user
 */
  renderArea(areaType: AreaType) {
    let imgFile;
    let style;
    let noImage: boolean = false;

    switch (areaType.toLowerCase()) {
      case AreaType.ofsc: {
        imgFile = 'logo-ofsc.png';
        style = 'width:64px; ';
        break;
      }
      case AreaType.salesforce: {
        imgFile = 'logo-salesforce.svg';
        style = 'width:64px; ';
        break;
      }
      case AreaType.tempozero: {
        imgFile = 'logo-tempozero.png';
        style = 'width:200px; ';
        break;
      }
      case AreaType.fusion: {
        imgFile = 'logo-fusion.jpg';
        style = 'width:80px;';
        break;
      }
      case AreaType.piteco: {
        imgFile = 'logo-piteco.png';
        style = 'width:64px;';
        break;
      }
      case AreaType.timesheet: {
        imgFile = 'Timesheet.png';
        style = 'width:64px;';
        break;
      }
      case AreaType.energy: {
        imgFile = 'Energy.png';
        style = 'width:64px;';
        break;
      }
      case AreaType.etime: {
        imgFile = 'eTime.png';
        style = 'width:64px;';
        break;
      }
      case AreaType.esight: {
        imgFile = 'eSightLogo.PNG';
        style = 'width:64px;';
        break;
      }
      case AreaType.mappature: {
        imgFile = 'mappature.PNG';
        style = 'width:64px;';
        break;
      }
      case AreaType.ecm: {
        imgFile = 'Ecm.png';
        style = 'width:64px;';
        break;
      }
      case AreaType.supervision_systems: {
        imgFile = 'Supervisione.png';
        style = 'width:64px;';
        break;
      }
      case AreaType.p2Web: {
        imgFile = 'O&M-NET.png'
        style = 'width:64px;';
        break;
      }
      case AreaType.floora: {
        imgFile = 'Floora.png';
        style = 'width:64px;';
        break;
      }
      case AreaType.account_google: {
        imgFile = 'account-google.png';
        style = 'width:30px;';
        break;
      }
      case AreaType.account_active_directory: {
        imgFile = 'active-directory.png';
        style = 'width:41px;';
        break;
      }
      case AreaType.prolabq: {
        imgFile = 'prolabq.png';
        style = 'height:24px;';
        break;
      }
      case AreaType.jaggaer: {
        imgFile = 'jaggaer.svg';
        style = 'height:20px;';
        break;
      }
      case AreaType.igateway: {
        imgFile = 'igateway.png';
        style = 'height:38px;';
        break;
      }
      case AreaType.bolla_lavoro: {
        imgFile = 'BollaLavoro.png';
        style = 'height:41px;';
        break;
      }
      case AreaType.request_pec: {
        imgFile = 'request_pec.png';
        style = 'height:41px;';
        break;
      }
      default: {
        noImage = true;
      }
    }

    let template;
    let parsedAreatype = areaType.replace(/\_/g, ' ').toLowerCase()
    let capitalizedAreaName = parsedAreatype.split(' ').map((singleWord) => {
      return singleWord.charAt(0).toUpperCase() + singleWord.slice(1)
    }).join(" ")
    if (noImage) {
      template = `<span class="color-grey text-capitalize">${parsedAreatype}</span>`;
    } else {
      template = `<span class="font-weight-100" title="${capitalizedAreaName}" >
      <img style="${style}" src="assets/images/area/${imgFile}"></img>
      </span>`;
    }

    return template;
  }


  renderBeneficiary(beneficiaries, type, full, meta) {
    const first_name = beneficiaries.first_name;
    const last_name = beneficiaries.last_name;
    const display_name = first_name + '  ' + last_name;

    return `<div class="d-flex align-items-center text-center">
                    <div class="m-r-10">
                        <a class="btn btn-circle btn-success text-white profile-no-effect">${first_name.charAt(0).toUpperCase() + '' + last_name.charAt(0).toUpperCase()}</a>
                    </div>
                    <div>
                        ${display_name}
                    </div>
                  </div>`;
  }

  renderTrasferteBeneficiary(beneficiaries, type, full, meta) {
    var display_name = "Trasferte di gruppo"
    var first_name = "T"
    var last_name = "G"

    if((full.business_trip && !full.business_trip.btGroup) || (!full.business_trip && !full.btGroup)) {
      first_name = beneficiaries[0].first_name;
      last_name = beneficiaries[0].last_name;
      display_name = first_name + '  ' + last_name;
    }

    return `<div class="d-flex align-items-center text-center">
                    <div class="m-r-10">
                        <a class="btn btn-circle btn-success text-white profile-no-effect">${first_name.charAt(0).toUpperCase() + '' + last_name.charAt(0).toUpperCase()}</a>
                    </div>
                    <div>
                        ${display_name}
                    </div>
                  </div>`;
  }

  renderBeneficiaries(beneficiaries, type, full, meta) {
    const first_name = beneficiaries[0].first_name;
    const last_name = beneficiaries[0].last_name;
    const display_name = first_name + '  ' + last_name;

    return `<div class="d-flex align-items-center text-center">
                  <div class="m-r-10">
                      <a class="btn btn-circle btn-success text-white profile-no-effect">${first_name.charAt(0).toUpperCase() + '' + last_name.charAt(0).toUpperCase()}</a>
                  </div>
                  <div>
                      ${display_name}
                  </div>
                </div>`;
  }



  /**
   * Transforma una mappa di oggetti in una mappa di oggetti con attributi snakecase , inoltre rimuove gli attributi con null value
   */
  convertToSnakeCaseAndClearMap(obj) {
    const mp = new Map<string, string>();
    Object.keys(obj).forEach(k => {
      const value = obj[k];
      if (value != null) {
        const snakeCaseKey = snakeCase(k);
        let isValid = true;
        if (typeof value === 'string') {
          if (isBlank(value)) {
            isValid = false;
          }
        }
        if (isValid) {
          mp.set(snakeCaseKey, value);

        }
      }


    });
    return mp;
  }

  convertMapToObj(map) {
    return Array.from(map).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
  }

}
