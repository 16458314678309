import { BeneficiaryResource } from './beneficiary.resource';

export class ExternalBeneficiaryResource extends BeneficiaryResource {

  static readonly TYPE = 'external';

  company_belonging: string;
  is_future_employee: boolean;
  terminated_relationship_at:string;

  constructor() {
    super(ExternalBeneficiaryResource.TYPE);
  }

}
