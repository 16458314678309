import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaAccountGoogleResource, } from './area-account-google.resource';
import { AreaAccountGoogleModel } from '../model/area-account-google.model';



export class AreaAccountGoogleSerializer implements Serializer { 


  fromJson(json: any): AreaAccountGoogleResource { 
  
    const resource = new AreaAccountGoogleResource();

    resource.notes = json.notes;
    resource.phone = json.phone;

    return resource;
  }

  toJson(model: AreaAccountGoogleModel): any  {

    const resource = new AreaAccountGoogleResource();
    
    resource.notes = model.notes;
    resource.phone = model.phone;

    return resource;
  }
}
