import { AreaResource, AreaType } from '../../area.resource';
import { Resource } from 'src/app/core/common/http/resource.model';

export class AreaOracleFieldServiceCloudResource extends AreaResource {
  static readonly TYPE = AreaType.ofsc;
  type_user: UserOfscTypeResource;
  skill_of_type_100: SkillOfscType100Resource;
  skill_of_type_50: SkillOfscType50Resource;
  membership_group: string;
  working_area: string;
  notes: string;

  constructor() {
    super(AreaOracleFieldServiceCloudResource.TYPE);
  }
}

export class UserOfscTypeResource extends Resource {
  id: number;
  type: string;
}
export class SkillOfscType100Resource extends Resource {
  id: number;
  type: string;
}
export class SkillOfscType50Resource extends Resource {
  id: number;
  type: string;
}



