import { AreaResource, AreaType } from '../../area.resource';

export class AreaRequestpecResource extends AreaResource {
  public static readonly TYPE =  AreaType.request_pec;

  email: string; 
  notes: string;

  constructor() {
    super(AreaRequestpecResource.TYPE);
  }
}


