import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {

    // il ruolo atteso è passato dalla route config come data property
    const expectedRole = route.data.expectedRole;

    const token = this.auth.getToken();
    // decodifica il token
    const tokenPayload = decode(token);

    if ( expectedRole ) {

      if (!this.auth.isAuthenticated() ||
        tokenPayload.role !== expectedRole
      ) {
        this.router.navigate(['/authentication/login']);
        return false;
      }
    }
    return true;
  }
}
