import { Injectable } from '@angular/core';
import { Serializer } from 'src/app/core/common/http/serializer';
import { SubmitterResource } from './submitter.resource';

@Injectable({
  providedIn: 'root',
})
export class SubmitterSerializer implements Serializer {
  fromJson(json: any): SubmitterResource {

    const resource = new SubmitterResource();
    resource.last_name = json.last_name;
    resource.first_name =  json.first_name;
    resource.email =  json.email;
    resource.image_url =  json.image_url;
    resource.display_name = json.display_name;
    resource.cdc = json.cdc;
    resource.matricola = json.matricola;

    return resource;
  }

  toJson(resource: SubmitterResource): any  {
    let res = new SubmitterResource();
    if(resource != null){
      res = new SubmitterResource();
      if (resource) {
        res = resource;
      } else if(resource.id){
        res.id = resource.id;
      }
    }
    return res;
  }
}
