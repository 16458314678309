import {
    Component,
    ViewEncapsulation,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FindInternalBeneficiaryTableComponent} from './find-internal-beneficiary-table/find-internal-beneficiary-table.component';
import { AppUtilService } from 'src/app/core/common/service/app-util.service';

@Component({selector: 'app-find-internal-beneficiary', templateUrl: './find-internal-beneficiary.html', encapsulation: ViewEncapsulation.None})
export class CercaUtenzeModalContentComponent implements OnInit {

    @ViewChild(FindInternalBeneficiaryTableComponent, {static: false})
    private userTable : FindInternalBeneficiaryTableComponent;

    @Output() dataEmitter = new EventEmitter<any>();

    btnApplicantIsDisabled : boolean;
    // Uso questo boolean per capire se richiamo la modale da una pagina di Applicativi
    isApplicativeSection : boolean = false;
    @Input()public isBeneficiary = true;
    @Input()public isHRBP = false;
    @Input()public isPayroll = false;
    @Input()public isDelega = false;
    @Input()public isTrasfertaGruppo = false;
    @Output()messageEvent = new EventEmitter<any>();

    trigger: boolean = false;

    userEmailSelected : any;
    user : {};

    constructor(
        private _appUtilService: AppUtilService,
        public activeModal : NgbActiveModal
    ) {}

    ngOnInit() {
        this.btnApplicantIsDisabled = true;
    }

    listeSelectEvent(event) {
        if (event.rowSelected) {
            this.btnApplicantIsDisabled = false;
        } else {
            this.btnApplicantIsDisabled = true;
        }
        if (event.user) {
            // Assegno all'oggetto user i valori di event.user
            this.user = event.user;
            this.userEmailSelected = event.user.email;
            this.messageEvent.emit(this.userEmailSelected)
        }
    }

    selectApplicant() {
        // Se uso questa modale dalla pagina di Applicativi, ho bisogno che mi restituisca sempre un risultato indipendentemente da isHRBP && isPayroll.
        if (this.isApplicativeSection == this._appUtilService.TRUE) {
            this.activeModal.close(this.user);
        } else {
            if (!this.isHRBP && !this.isPayroll) {
                this.activeModal.close(this.user);
            } else {
                this.messageEvent.emit(this.user);
            }
        }
    }

    selectApplicantAndKeepItOpen() {
        this.dataEmitter.emit(this.user);
        this.trigger = true;
        setTimeout(() => (this.trigger = false), 1000);
    }

    close() {
        this.activeModal.dismiss('No applicant selected');
    }
}
