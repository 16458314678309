
import { AreaResource, AreaType } from '../../area.resource';
import { EcmListResource } from './ecmListResource.resource';

export class AreaEcmResource extends AreaResource {
   public static readonly TYPE =  AreaType.ecm;

   assignment_groups: EcmListResource[];
   notes: string;

   constructor() {
    super(AreaEcmResource.TYPE);
   }
}

