import { Resource } from 'src/app/core/common/http/resource.model';

export class CdcResource extends Resource {

  udb_code: string;
  area_code: string;
  cdc_code: string;

  constructor() {
    super();
  }

}
