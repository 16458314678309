import { Resource } from 'src/app/core/common/http/resource.model';

export class ActionResource extends Resource {

  comment: string;
  submitter_id: string;
  type: ActionType;

  constructor() {
    super();
  }

}








export enum ActionType {
  APPROVE = 'APPROVE',
  DENY = 'DENY',
  CANCEL = 'CANCEL',
  TAKING_CHARGE_HRBP = "TAKING_CHARGE_HRBP",
  NO_SHOW = "NO_SHOW",
  CENSUS_CLOSE = "CENSUS_CLOSE",
  ACTION_BUTTON_ID_REJECTED = "ACTION_BUTTON_ID_REJECTED",
  ACTION_BUTTON_ID_REJECTED_PAYROLL = "ACTION_BUTTON_ID_REJECTED_PAYROLL",
  DELEGA = "DELEGA"
}



