import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaFusionResource, UserOfAfTypeResource } from './areafusion.resource';
import { AreaFusionModel } from '../model/area-fusion.model';


export class AreaFusionSerializer implements Serializer {

  fromJson(json: any): AreaFusionResource {
    const resource = new AreaFusionResource();
    resource.type_user = new UserOfAfTypeResource();
    resource.type_user.id = json.type_user.id;
    resource.type_user.type = json.type_user.type;
    resource.url = json.url;
    resource.buyer = json.buyer;
    resource.requester = json.requester;
    resource.approver = json.approver;
    resource.approval_power = json.approval_power;
    resource.approval_power_cost_centers = json.approval_power_cost_centers;
    resource.notes = json.notes;
    return resource;
  }

  toJson(model: AreaFusionModel): any  {
    const resource = new AreaFusionResource();
    resource.type_user = new UserOfAfTypeResource();
    resource.type_user.id = model.type_user[0].id;
    resource.type_user.type = model.type_user[0].type;
    resource.url = model.url;
    resource.approval_power = model.approval_power;
    resource.approval_power_cost_centers = model.approval_power_cost_centers;
    resource.notes = model.notes;
    return resource;
  }
}
