import { Model } from 'src/app/core/common/model/model';

export class CdcModel extends Model {
  id: string;
  cdc_code: string;

  constructor() {
    super();
  }

}
