import { Resource } from '../../common/http/resource.model';

export class User extends Resource {
   id: string;
   name: string;
   email: string;
   imageUrl: string;
   emailVerified: boolean;
   provider: string;

  constructor() {
    super();
  }

}
