import { AreaModel } from "../../area.model";


export class AreaBollalavoroModel extends AreaModel  {


    public type_user: any[] = [];
    public notes: string; 

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
