import { Serializer } from 'src/app/core/common/http/serializer';
import { CommessaModel } from '../model/commessa.model';
import { CommessaResource } from './commessa.resource';

export class CommessaSerializer implements Serializer {

  fromJson(json: any): CommessaModel {
    
    const model = new CommessaModel();
    model.id = json.udb_code + '-' + json.area_code + '-' + json.cdc_code + '- ' + json.segment_code;
    model.project_name = json.project_name;
    model.cdc_type = json.cdc_type;
    model.project_description = json.project_description;

    return model;
  }

  toJson(model: CommessaModel): any  {
    
    const commessaResource = new CommessaResource();
    const arr = model.id.split('-');

    commessaResource.udb_code = arr[0];
    commessaResource.area_code = arr[1];
    if(arr[2] != "undefined") commessaResource.cdc_code = arr[2];
    else commessaResource.cdc_code = model.cdc_type

    commessaResource.segment_code = arr[3];
    commessaResource.active = model.active;
    commessaResource.project_name = model.project_name;
    commessaResource.project_description = model.project_description;
    
    return commessaResource;
  }

}
