import { Resource } from 'src/app/core/common/http/resource.model';
import { GenericFields } from './genericFields.resource'

export class AdditionalFields extends Resource {

    fields:GenericFields;
 
    constructor() {
        super();
    }

}



