import { Resource } from 'src/app/core/common/http/resource.model';

export class CommessaResource extends Resource {

  udb_code: string;
  area_code: string;
  cdc_code: string;
  segment_code: string;
  project_name: string;
  project_description: string;
  active: boolean;
  cdc_type: string;

  constructor() {
    super();
  }

}
