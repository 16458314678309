import { AreaModel } from "../../../area.model";

export class AreaP2WebModel extends AreaModel  {

    public type_module: any[] = []; 
    public cdc: string; 
    public notes: string; 

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
