import { BeneficiaryResource } from './beneficiary.resource';

export class InternalBeneficiaryResource extends BeneficiaryResource {

  static readonly TYPE = 'internal';

  user_id: number;

  constructor() {
    super(InternalBeneficiaryResource.TYPE);
  }

}
