import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaBollalavoroModel } from '../model/area-bollalavoro.model';
import { AreaBollalavoroResource , UserTypeResource} from './area-bollalavoro.resource';


export class AreaBollalavoroSerializer implements Serializer { 

  fromJson(json: any): AreaBollalavoroResource { 

    const resource = new AreaBollalavoroResource();

    resource.type_user = new UserTypeResource();
    resource.type_user.id = json.type_user.id;
    resource.type_user.type = json.type_user.type;
    resource.notes = json.notes;

    return resource;
  }

  toJson(model: AreaBollalavoroModel): any  {

    const resource = new AreaBollalavoroResource();

    resource.type_user = new UserTypeResource();
    resource.type_user.id = model.type_user[0].id;
    resource.type_user.type = model.type_user[0].type;
    resource.notes = model.notes;
    
    return resource;
  }
}
