import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaModel } from './area.model';
import { AreaResource } from './area.resource';
import { AreaOracleFieldServiceCloudSerializer } from './area-oracle-field-service-cloud/resource/area-ofsc.serializer';
import { AreaSalesforceSerializer } from './area-salesforce/resource/areasalesforce.serializer';
import { AreaTempozeroSerializer } from './area-tempozero/resource/area-tempozero.serializer';
import { AreaOracleFieldServiceCloudResource } from './area-oracle-field-service-cloud/resource/area-ofsc.resource';
import { AreaSalesforceResource } from './area-salesforce/resource/areasalesforce.resource';
import { AreaOracleServiceCloudModel } from './area-oracle-field-service-cloud/model/area-osfc-.model';
import { AreaSalesforceModel } from './area-salesforce/model/area-salesforce.model';
import { AreaTempozeroModel } from './area-tempozero/model/area-tempozero.model';
import { AreaTempozeroResource } from './area-tempozero/resource/area-tempozero.resource';
import { AreaSummaryResource } from './area-summary.resource';
import { AreaFusionSerializer } from './area-fusion/resource/areafusion.serializer';
import { AreaPitecoSerializer } from './area-piteco/resource/areapiteco.serializer';
import { AreaFusionModel } from './area-fusion/model/area-fusion.model';
import { AreaFusionResource } from './area-fusion/resource/areafusion.resource';
import { AreaPitecoModel } from './area-piteco/model/area-piteco.model';
import { AreaPitecoResource } from './area-piteco/resource/areapiteco.resource';
import { AreaFlooraResource } from './area-floora/area-floora/resource/area-floora.resource';
import { AreaFlooraModel } from './area-floora/area-floora/model/area-floora.model';
import { AreaP2WebSerializer } from './area-p2Web/area-p2-web/resource/area-p2-web.serializer';
import { AreaP2WebResource } from './area-p2Web/area-p2-web/resource/area-p2-web.resource';
import { AreaP2WebModel } from './area-p2Web/area-p2-web/model/area-p2-web.model';
import { AreaFlooraSerializer } from './area-floora/area-floora/resource/area-floora.serializer';
import { AreaEcmSerializer } from './area-ecm/resource/area-ecm-serializer';
import { AreaEcmResource } from './area-ecm/resource/area-ecm.resource';
import { AreaEcmModel } from './area-ecm/model/area-ecm.model';
import { AreaEnergySerializer } from './area-energy/resource/area-energy-serializer';
import { AreaEnergyResource } from './area-energy/resource/area-energy.resource';
import { AreaEnergyModel } from './area-energy/model/area-energy.model';
import { AreaEtimeSerializer } from './area-etime/resource/area-etime-serializer';
import { AreaEtimeResource } from './area-etime/resource/area-etime.resource';
import { AreaEtimeModel } from './area-etime/model/area-etime.model';
import { AreaTimeSheetSerializer } from './area-timesheet/resource/area-timesheet.serializer';
import { AreaTimeSheetResource } from './area-timesheet/resource/area-timesheet.resource';
import { AreaTimeModel } from './area-timesheet/model/area-timesheet.model';
import { AreaEsightSerializer } from './area-esight/resource/area-esight.serializer';
import { AreaEsightResource } from './area-esight/resource/area-esight.resource';
import { AreaEsightModel } from './area-esight/model/area-esight.model';
import { AreaMappatureSerializer } from './area-mappature/resource/area-mappature.serializer';
import { AreaMappatureResource } from './area-mappature/resource/area-mappature.resource';
import { AreaMappatureModel } from './area-mappature/model/area-mappature.model';
import { AreaSupervisionSerializer } from './area-supervision/resource/area.supervision-serializer';
import { AreaSupervisionResource } from './area-supervision/resource/area-supervision.resource';
import { AreaSupervisionModel } from './area-supervision/model/area-supervision.model';
import { AreaAccountGoogleSerializer } from './area-account-google/resource/area-account-google-serializer';
import { AreaAccountGoogleResource } from './area-account-google/resource/area-account-google.resource';
import { AreaAccountGoogleModel } from './area-account-google/model/area-account-google.model';
import { AreaActiveDirectorySerializer } from './area-active-directory/resource/area-active-directory-serializer';
import { AreaActiveDirectoryResource } from './area-active-directory/resource/area-active-directory.resource';
import { AreaActiveDirectoryModel } from './area-active-directory/model/area-active-directory.model';
import { AreaIGatewaySerializer } from './area-igateway/resource/area-igateway-serializer';
import { AreaIgatewayResource } from './area-igateway/resource/area-igateway.resource';
import { AreaIGatewayModel } from './area-igateway/model/area-igateway.model';
import { AreaProlabQSerializer } from './area-prolabq/resource/area-prolabq-serializer';
import { AreaProlabQResource } from './area-prolabq/resource/area-prolabq.resource';
import { AreaProlabQModel } from './area-prolabq/model/area-prolabq.model';
import { AreajaggaerSerializer } from './area-jaggaer/resource/area-jaggaer-serializer';
import { AreajaggaerResource } from './area-jaggaer/resource/area-jaggaer.resource';
import { AreaJaggaerModel } from './area-jaggaer/model/area-jaggaer.model';
import { AreaBollalavoroSerializer } from './area-bollalavoro/resource/area-bollalavoro-serializer';
import { AreaBollalavoroResource } from './area-bollalavoro/resource/area-bollalavoro.resource';
import { AreaBollalavoroModel } from './area-bollalavoro/model/area-bollalavoro.model';
import { AreaRequestpecModel } from './area-requestpec/model/area-requestpec.model';
import { AreaRequestpecSerializer } from './area-requestpec/resource/area-requestpec-serializer';
import { AreaRequestpecResource } from './area-requestpec/resource/area-requestpec.resource';


export class AreaSerializer implements Serializer {

  areaTempozeroSerializer: AreaTempozeroSerializer;
  areaOracleFieldServiceCloudSerializer: AreaOracleFieldServiceCloudSerializer;
  areaSalesforceSerializer: AreaSalesforceSerializer;
  areaFusionSerializer: AreaFusionSerializer;
  areaPitecoSerializer: AreaPitecoSerializer;
  areaFlooraSerializer: AreaFlooraSerializer;
  areaP2WebSerializer: AreaP2WebSerializer;
  areaEcmSerializer: AreaEcmSerializer;
  areaEnergySerializer: AreaEnergySerializer;
  areaEtimeSerializer: AreaEtimeSerializer;
  areaTimeSheetSerializer: AreaTimeSheetSerializer;
  areaEsightSerializer: AreaEsightSerializer;
  areaMappatureSerializer: AreaMappatureSerializer;
  areaSupervisionSerializer: AreaSupervisionSerializer;
  areaAccountGoogleSerializer: AreaAccountGoogleSerializer;
  areaActiveDirectorySerializer: AreaActiveDirectorySerializer;
  areaIGatewaySerializer: AreaIGatewaySerializer;
  areaProlabQSerializer: AreaProlabQSerializer;
  areaJaggaerSerializer: AreajaggaerSerializer;
  areaBollalavoroSerializer: AreaBollalavoroSerializer;
  areaRequestpecSerializer: AreaRequestpecSerializer;


  instanceList:any = [];
  


  constructor() {
    this.areaTempozeroSerializer = new AreaTempozeroSerializer();
    this.areaOracleFieldServiceCloudSerializer = new AreaOracleFieldServiceCloudSerializer();
    this.areaSalesforceSerializer = new AreaSalesforceSerializer();
    this.areaFusionSerializer = new AreaFusionSerializer();
    this.areaPitecoSerializer = new AreaPitecoSerializer();
    this.areaFlooraSerializer = new AreaFlooraSerializer();
    this.areaP2WebSerializer = new AreaP2WebSerializer();
    this.areaEcmSerializer = new AreaEcmSerializer();
    this.areaEnergySerializer = new AreaEnergySerializer();
    this.areaEtimeSerializer = new AreaEtimeSerializer();
    this.areaTimeSheetSerializer = new AreaTimeSheetSerializer();
    this.areaEsightSerializer = new AreaEsightSerializer();
    this.areaMappatureSerializer = new AreaMappatureSerializer();
    this.areaSupervisionSerializer = new AreaSupervisionSerializer();
    this.areaAccountGoogleSerializer = new AreaAccountGoogleSerializer();
    this.areaActiveDirectorySerializer = new AreaActiveDirectorySerializer();
    this.areaIGatewaySerializer = new AreaIGatewaySerializer;
    this.areaProlabQSerializer = new AreaProlabQSerializer;
    this.areaJaggaerSerializer = new AreajaggaerSerializer;
    this.areaBollalavoroSerializer = new AreaBollalavoroSerializer;
    this.areaRequestpecSerializer = new AreaRequestpecSerializer;
    this.initInstanceList();
  }

  initInstanceList(){
    this.instanceList = [
      {instanceClass: AreaOracleServiceCloudModel, relativeClass: this.areaOracleFieldServiceCloudSerializer},
      {instanceClass: AreaSalesforceModel, relativeClass: this.areaSalesforceSerializer},
      {instanceClass: AreaTempozeroModel, relativeClass: this.areaTempozeroSerializer},
      {instanceClass: AreaFusionModel, relativeClass: this.areaFusionSerializer},
      {instanceClass: AreaPitecoModel, relativeClass: this.areaPitecoSerializer},
      {instanceClass: AreaFlooraModel, relativeClass: this.areaFlooraSerializer},
      {instanceClass: AreaP2WebModel, relativeClass: this.areaP2WebSerializer},
      {instanceClass: AreaEcmModel, relativeClass: this.areaEcmSerializer},
      {instanceClass: AreaEnergyModel, relativeClass: this.areaEnergySerializer},
      {instanceClass: AreaEtimeModel, relativeClass: this.areaEtimeSerializer},
      {instanceClass: AreaTimeModel, relativeClass: this.areaTimeSheetSerializer},
      {instanceClass: AreaEsightModel, relativeClass: this.areaEsightSerializer},
      {instanceClass: AreaMappatureModel, relativeClass: this.areaMappatureSerializer},
      {instanceClass: AreaSupervisionModel, relativeClass: this.areaSupervisionSerializer},
      {instanceClass: AreaAccountGoogleModel, relativeClass: this.areaAccountGoogleSerializer},
      {instanceClass: AreaActiveDirectoryModel, relativeClass: this.areaActiveDirectorySerializer},
      {instanceClass: AreaIGatewayModel, relativeClass: this.areaIGatewaySerializer},
      {instanceClass: AreaProlabQModel, relativeClass: this.areaProlabQSerializer},
      {instanceClass: AreaJaggaerModel, relativeClass: this.areaJaggaerSerializer},
      {instanceClass: AreaBollalavoroModel, relativeClass: this.areaBollalavoroSerializer},
      {instanceClass: AreaRequestpecModel, relativeClass: this.areaRequestpecSerializer}
    ];
  }



  fromJson(json: any): AreaResource {
    switch (json.type) {
      case AreaOracleFieldServiceCloudResource.TYPE:
        return this.areaOracleFieldServiceCloudSerializer.fromJson(json);
      case AreaSalesforceResource.TYPE: 
        return this.areaSalesforceSerializer.fromJson(json);
      case AreaTempozeroResource.TYPE: 
        return this.areaTempozeroSerializer.fromJson(json);
      case AreaFusionResource.TYPE: 
        return this.areaFusionSerializer.fromJson(json);
      case AreaPitecoResource.TYPE:
        return this.areaPitecoSerializer.fromJson(json);
      case AreaFlooraResource.TYPE:
        return this.areaFlooraSerializer.fromJson(json);
      case AreaP2WebResource.TYPE:
        return this.areaP2WebSerializer.fromJson(json);
      case AreaEcmResource.TYPE:
        return this.areaEcmSerializer.fromJson(json);
      case AreaEnergyResource.TYPE:
        return this.areaEnergySerializer.fromJson(json);
      case AreaEtimeResource.TYPE:
        return this.areaEtimeSerializer.fromJson(json);
      case AreaTimeSheetResource.TYPE:
        return this.areaTimeSheetSerializer.fromJson(json);
      case AreaEsightResource.TYPE:
        return this.areaEsightSerializer.fromJson(json);
      case AreaMappatureResource.TYPE:
        return this.areaMappatureSerializer.fromJson(json);
      case AreaSupervisionResource.TYPE:
        return this.areaSupervisionSerializer.fromJson(json);
      case AreaAccountGoogleResource.TYPE:
        return this.areaAccountGoogleSerializer.fromJson(json);
      case AreaActiveDirectoryResource.TYPE:
        return this.areaActiveDirectorySerializer.fromJson(json);
      case AreaIgatewayResource.TYPE:
        return this.areaIGatewaySerializer.fromJson(json);
      case AreaProlabQResource.TYPE:
        return this.areaProlabQSerializer.fromJson(json);
      case AreajaggaerResource.TYPE:
        return this.areaJaggaerSerializer.fromJson(json);
      case AreaBollalavoroResource.TYPE:
        return this.areaBollalavoroSerializer.fromJson(json);
      case AreaRequestpecResource.TYPE:
        return this.areaRequestpecSerializer.fromJson(json);
      default:
        const asr = new AreaSummaryResource();
        asr.area_type = json.area_type;
        return asr;
    }
  }

  toJson(model: AreaModel): any {
    for(let d of this.instanceList){
      if (model instanceof d.instanceClass){
        return d.relativeClass.toJson(model);
      }
    }
  }

}
