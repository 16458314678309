import { Serializer } from 'src/app/core/common/http/serializer';
import { Link } from './link.resource';

export class LinkSerializer implements Serializer {

  fromJson(json: any): Link {
    const resource = new Link();
    resource.rel = json.rel;
    resource.href = json.href;
    return resource;
  }

  toJson(resource: Link): any  {
    return {};
  }
}
