import { Component, Input } from '@angular/core';
import { SubmitterResource } from 'src/app/richieste/resource/submitter.resource';
import { ApprovalService } from '../../approvazioni/service/approval.service';

@Component({
  selector: 'app-submitter-detail',
  templateUrl: './submitter-detail.html',
  styleUrls: ['./submitter-detail.component.css']
})
export class SubmitterDetailComponent {
  isDataAvailable: false;
  @Input() public submitter: SubmitterResource;
  constructor(private _approvalService: ApprovalService ) {}
  renderUser(): any {
    return this._approvalService.renderUser(this.submitter);
  }
}

