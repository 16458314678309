import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaSalesforceResource, RoleTypeResource, ManagerResource} from './areasalesforce.resource';
import { AreaSalesforceModel } from '../model/area-salesforce.model';


export class AreaSalesforceSerializer implements Serializer {
  fromJson(json: any): AreaSalesforceResource {
    const resource = new AreaSalesforceResource();
    resource.role_type = new RoleTypeResource();
    resource.role_type.id = json.role_type.id;
    resource.role_type.type = json.role_type.type;
    resource.udb_type_code = json.udb_type_code;
    resource.area_type_code = json.area_type_code;
    resource.cdc_type_code = json.cdc_type_code;
    resource.manager = new ManagerResource();
    resource.manager.id = json.manager.id;
    resource.manager.display_name = json.manager.display_name;
    resource.notes = json.notes;
    return resource;
  }

  toJson(model: AreaSalesforceModel): any  {
    const resource = new AreaSalesforceResource();
    resource.role_type = new RoleTypeResource();
    resource.role_type.id =  model.role[0].id;
    resource.udb_type_code = model.udb[0].code;
    resource.area_type_code = model.area[0].code;
    resource.cdc_type_code = model.cdc[0].code;
    resource.manager = new ManagerResource();
    resource.manager.id = model.manager[0].id;
    resource.manager.display_name = model.manager[0].display_name;
    resource.notes = model.notes;
    return resource;
  }
}
