import { Serializer } from 'src/app/core/common/http/serializer';
import { BGroupModel } from '../model/belonging_groups.model';
import { BelongingGroupResource } from './belonging_groups.resource';

export class BelongingSerializer implements Serializer {

  fromJson(json: any): BGroupModel {
    
    const model = new BGroupModel();
    model.id = json.id;
    model.description = json.description;

    return model;
  }

  toJson(model: BGroupModel): any  {
    
    
    const belongingGroupResource = new BelongingGroupResource();
    const arr = model.id;
    const arr2 = model.description;

    belongingGroupResource.id = arr;
    belongingGroupResource.description = arr2;

 

    return belongingGroupResource;
  }

}
