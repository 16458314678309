
import { Resource } from 'src/app/core/common/http/resource.model';
import { AreaResource, AreaType } from '../../../area.resource';

export class AreaFlooraResource extends AreaResource {
   public static readonly TYPE =  AreaType.floora;

   type_portal: TypePortalResource;
   type_user: UserFTypeResource;
   profile: string;
   notes: string;

   constructor() {
    super(AreaFlooraResource.TYPE);
   }
}

export class TypePortalResource extends Resource {
  id: number;
  description: string;
}

export class UserFTypeResource extends Resource {
  id: string;
  type: string;
  constructor() {
    super();
  }
}