import { AreaResource, AreaType } from '../../area.resource';
import { Resource } from 'src/app/core/common/http/resource.model';

export class AreaPitecoResource extends AreaResource {
   public static readonly TYPE =  AreaType.piteco;

   type_user: UserOpAfTypeResource;
   belonging_office: BelongingOfficeResource;
   notes: string;

   constructor() {
    super(AreaPitecoResource.TYPE);
   }
}

export class UserOpAfTypeResource extends Resource {
  id: number;
  type: string;

  constructor() {
    super();

  }
}
export class BelongingOfficeResource extends Resource {
  id: number;
  description: string;

  constructor() {
    super();

  }
}


