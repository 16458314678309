import { AreaModel } from '../../area.model';

export class AreaSalesforceModel extends AreaModel  {

    public role: any[] = [];
    public udb: any[] = [];
    public area: any[] = [];
    public cdc: any[] = [];
    public manager: any[] = [];
    public notes: string;

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
