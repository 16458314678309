import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'angular-calendar';
import { NgbModalModule, NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { QuillModule } from 'ngx-quill';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import{	BeneficiaryDataInternalComponent } from 'src/app/richieste/applicant-data/beneficiary-data-internal.component copy';
import{ BeneficiaryDataExternalComponent } from 'src/app/richieste/applicant-data/beneficiary-data-external.component';
import{ BeneficiaryDataComponent } from 'src/app/richieste/applicant-data/beneficiary-data.create.component';
import{ AddExternalBeneficiaryComponent } from 'src/app/richieste/applicant-data/add-external-beneficiary/add-external-beneficiary.component';
import{ CercaUtenzeModalContentComponent } from 'src/app/richieste/applicant-data/find-internal-beneficiary/find-internal-beneficiary.component';
import{ FindInternalBeneficiaryTableComponent } from 'src/app/richieste/applicant-data/find-internal-beneficiary/find-internal-beneficiary-table/find-internal-beneficiary-table.component';
import{ BeneficiaryDetailComponent } from 'src/app/richieste/applicant-data/beneficiary-detail/beneficiary-detail.component';




// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbModalModule,
    ToastrModule.forRoot({
      maxOpened : 10,
      preventDuplicates: true,
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
    }),
    CalendarModule.forRoot(),
    BlockUIModule.forRoot(),
    QuillModule.forRoot(),
    DragulaModule.forRoot(),
    PerfectScrollbarModule,
    DataTablesModule,
    NgMultiSelectDropDownModule,
    AngularMultiSelectModule,
    TranslateModule,
    SharedModule
  ],
  declarations: [

    BeneficiaryDataInternalComponent,
    BeneficiaryDataExternalComponent,
    BeneficiaryDataComponent,
    AddExternalBeneficiaryComponent,
    CercaUtenzeModalContentComponent,
    FindInternalBeneficiaryTableComponent,
    BeneficiaryDetailComponent
  ],
  providers: [
    NgbActiveModal,
    DatePipe
  ],
  entryComponents: [

  ],
  exports: [
    BeneficiaryDataInternalComponent,
    BeneficiaryDataExternalComponent,
    BeneficiaryDataComponent,
    AddExternalBeneficiaryComponent,
    CercaUtenzeModalContentComponent,
    FindInternalBeneficiaryTableComponent,
    BeneficiaryDetailComponent
   ]
})
export class ApplicationDataModule { }
