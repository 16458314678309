import { Injectable } from "@angular/core";
import { User } from "./user.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { UserSerializer } from "./user.serializer";
import { Observable } from "rxjs";
import { ResourceService } from "../../../core/common/http/resource-service.service";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService extends ResourceService<User> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.backend.baseUrl,
      "user",
      new UserSerializer()
    );
  }

  currentUser(): Observable<HttpResponse<User>> {
    return this.httpClient
      .get(`${this.baseUrl}/${this.uri}/me`, { observe: "response" })
      .map((res: HttpResponse<User>) => this.convertResponse(res));
  }
}
