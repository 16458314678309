import { Resource } from 'src/app/core/common/http/resource.model';

export class FunctionsResource extends Resource {

  id: number;
  description: string;

  constructor() {
    super();
  }

}
