import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaEnergyModel } from '../model/area-energy.model';
import { AreaEnergyResource, AssignmentResource, ProfileResource } from './area-energy.resource';


export class AreaEnergySerializer implements Serializer { 

  fromJson(json: any): AreaEnergyResource { 

    const resource = new AreaEnergyResource();

    resource.profile_enabled = new AssignmentResource();
    resource.profile_enabled.profile = new ProfileResource();
    resource.profile_enabled.profile.id = json.profile_enabled.profile.id;
    resource.profile_enabled.profile.description = json.profile_enabled.profile.description;
    resource.profile_enabled.enabled_disabled = json.profile_enabled.enabled_disabled;
    resource.cdc_associated = json.cdc_associated;
    resource.hold = json.hold;
    resource.notes = json.notes;

    return resource;
  }

  toJson(model: AreaEnergyModel): any  {

    const resource = new AreaEnergyResource();
    resource.profile_enabled = new AssignmentResource();
    resource.profile_enabled.profile = new ProfileResource();
    resource.profile_enabled.profile.id = model.profile_enabled[0].id;
    resource.profile_enabled.enabled_disabled = model.enable_disabled;
    resource.cdc_associated = model.cdc_associated;

    resource.cdc_associated = resource.cdc_associated.filter((el:any) => el.value);

    if (model.hold === undefined){
       model.hold = null;
    } else {
      resource.hold = model.hold;
    }
  
    resource.notes = model.notes;
  
    return resource;

  }
}
