import { ExternalBeneficiaryResource } from './external-beneficiary.model';
import { Serializer } from 'src/app/core/common/http/serializer';
import { ExternalBeneficiaryModel } from '../add-external-beneficiary/model/external.applicant.model';
import { BeneficiaryResource, BeneficiarySummaryResource } from './beneficiary.resource';
import { BeneficiaryModel } from '../model/beneficiary.model';
import { InternalBeneficiaryResource } from './internal-beneficiary.resource';
import { InternalBeneficiaryModel } from '../find-internal-beneficiary/model/internal.beneficiary.model';

export class BeneficiarySerializer implements Serializer {

  fromJson(json: any): BeneficiaryResource {
    if(!json.email) {
      json = json[0];
    }
    let resource ;
    if (json.type === ExternalBeneficiaryResource.TYPE) {
      resource = new ExternalBeneficiaryResource();
      resource.company_belonging = json.company_belonging;
      resource.id = json.id;
      resource.is_future_employee = json.is_future_employee;
      resource.terminated_relationship_at = json.terminated_relationship_at;
    } else if (json.type === InternalBeneficiaryResource.TYPE) {
      resource = new InternalBeneficiaryResource();
      resource.cdc_code = json.cdc_code;
      resource.image_url = json.image_url;
      resource.user_id = json.user_id;

    } else {
      resource = new BeneficiarySummaryResource('summary');
      resource.image_url = json.image_url;
    }

    resource.email = json.email;
    resource.first_name = json.first_name;
    resource.last_name = json.last_name;
    resource.matricola = json.matricola;

    return resource;

  }

  toJson(model: BeneficiaryModel): any  {
    let resource ;
    if (model instanceof ExternalBeneficiaryModel ) {
      resource = new ExternalBeneficiaryResource();
      resource.company_belonging = model.company_belonging;
      resource.is_future_employee = model.is_future_employee;
      resource.terminated_relationship_at = model.terminated_relationship_at;
      resource.id = model.id;
    } else if(model instanceof InternalBeneficiaryModel){
      resource = new InternalBeneficiaryResource();
      resource.user_id = model.user_id;
      resource.cdc_code = model.cdc_code;
    }
    resource.first_name = model.firstName;
    resource.last_name = model.lastName;
    resource.email = model.email;
    return resource;
  }

    toHiringJson(model: BeneficiaryModel): any  {
    let resource ;
    resource = new InternalBeneficiaryResource();
    if(model instanceof InternalBeneficiaryModel){
      resource.user_id = model.user_id;
    }
    return resource;

  }
}
