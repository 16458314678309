import { AreaModel } from '../../area.model';

export class AreaTimeModel extends AreaModel {
  // Definisco un construttore con valori di default vuoti in sostituzione del costruttore senza parametri

    public typology: [];
    public belonging_groups: [];
    public cdc: string;
    public notes: string;

  constructor() {
    super();
  }

}
